import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import styled from "styled-components";
import _Button from "../../Button";
import HistoryContext from "../../../context/HistoryContext";
import { UserType } from "../../../../constant/userType";
import { PaymentOutcome } from "./PaymentOutcome";

type Props = {
  onLogin: () => void;
  isLoggedIn: boolean;
  paidCourse: boolean;
  cancelled?: boolean;
  onPaymentRequired?: () => void;
};

const SignUpOrPayOverlay: React.FC<Props> = ({ onLogin, isLoggedIn, paidCourse, cancelled, onPaymentRequired }) => {
  const history = useContext(HistoryContext);

  const paidCourseDescription = isLoggedIn ? "Payment required" : "Unlock for full content";

  const actionDescription = paidCourse ? paidCourseDescription : "Register for free for full content";

  return (
    <Container>
      <div style={{ zIndex: 3, padding: 16 }}>
        {cancelled ? (
          <PaymentOutcome outcome="cancelled" />
        ) : (
          <Button
            fullWidth={false}
            height="medium"
            onClick={() =>
              paidCourse && isLoggedIn && onPaymentRequired
                ? onPaymentRequired()
                : history.push(`/sign-up`, UserType.AllUsers)
            }
            color="primary"
          >
            <Typography variant="body1" style={{ margin: "0 16px" }} align="center">
              {actionDescription}
            </Typography>
          </Button>
        )}
        {!isLoggedIn && (
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              style={{ display: "inline", backgroundColor: "white", boxShadow: "0 0 10px white" }}
            >
              Already registered?{" "}
              <span
                role="button"
                tabIndex={0}
                onClick={onLogin}
                onKeyDown={onLogin}
                style={{ color: "#0055D7", cursor: "pointer" }}
              >
                Login
              </span>
            </Typography>
          </div>
        )}
      </div>
      <Backdrop />
    </Container>
  );
};

const Backdrop = styled.div({
  position: "absolute",
  opacity: 0.8,
  backgroundColor: "white",
  width: "100%",
  height: "100%",
  zIndex: 2,
});

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Button = styled(_Button)`
  margin: auto;
  min-width: 205px;
`;

export default SignUpOrPayOverlay;
