import React, { CSSProperties } from "react";

type Props = {
  svgContent: any;
  viewBox: any;
  width: number;
  height: number;
  title?: string;
  style?: CSSProperties | undefined;
};

export const SvgIcon: React.FC<Props> = ({ svgContent, viewBox, width = 20, height = 20, title, style }) => {
  const hasTitle = title !== undefined;
  const labelledbyElement = hasTitle ? "title" : undefined;

  return (
    <svg
      className="link"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      aria-labelledby={labelledbyElement}
      style={style}
    >
      {hasTitle ? <title id="title">{title}</title> : null}
      {svgContent}
    </svg>
  );
};
