import { NumberOfPreviewLessonsForFreeCourse } from "../constant/anonymousRestrictions";
import { CourseAccess } from "../entity/Course";
import { Access, Course, CourseItem, Map, UserCourseAccess } from "../generated/graphql";

export const isPublicCourse = (course: Course | null): boolean => {
  return course != null && (course.access === CourseAccess.Public || course.access === CourseAccess.PublicNotListed);
};

export const getCourseName = (course: Course | null, map: Map): string => {
  // The course name is only reliable if it's a public course
  if (course && isPublicCourse(course)) {
    return course.name;
  }
  return map.name;
};

export const isPaidCourse = (course: Pick<Course, 'requirePayment'> | null | undefined): boolean => !!course?.requirePayment;


export const getNumberOfPreviewLessons = (course: Pick<Course, 'requirePayment'> | null | undefined): number => isPaidCourse(course) ? 0 : NumberOfPreviewLessonsForFreeCourse


export const getLockedItems = (isLoggedIn: boolean | undefined,
  numberOfPreviewLessons: number,
  courseItems: CourseItem[],
  userCourseAccess: UserCourseAccess | undefined): CourseItem[] => {

  if (!isLoggedIn || !userCourseAccess || userCourseAccess.access === Access.None) {
    return courseItems.slice(numberOfPreviewLessons)
  }

  if (userCourseAccess.access === Access.Free
    || userCourseAccess.access === Access.Shared
    || userCourseAccess.access === Access.Paid) {
    return []
  }
  // Should never get here but to be on the safe side, say all items are locked
  // If this is ever reached, it means that there is a new access type that is not handled and locking everything
  // will mean users complain and we fix the bug
  return courseItems
}
