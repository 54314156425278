export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum Access {
  None = 'None',
  Free = 'Free',
  RequiresPayment = 'RequiresPayment',
  Paid = 'Paid',
  Shared = 'Shared'
}

export type Achievement = {
  __typename?: 'Achievement';
  id: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  questionId: Scalars['ID'];
  mark?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type AnswerAttempt = {
  __typename?: 'AnswerAttempt';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user: UserSummary;
  mapId: Scalars['ID'];
  quizId: Scalars['ID'];
  lessonId: Scalars['ID'];
  startDate?: Maybe<Scalars['Date']>;
  answers: Array<Answer>;
};

export type AnswerAttemptInput = {
  id?: Maybe<Scalars['ID']>;
  mapId: Scalars['ID'];
  quizId: Scalars['ID'];
  answers: Array<AnswerInput>;
};

export type AnswerInput = {
  questionId: Scalars['ID'];
  mark?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
};

export enum AnswerMode {
  Exercise = 'EXERCISE',
  Quiz = 'QUIZ'
}

export type AnswerPoint = {
  __typename?: 'AnswerPoint';
  title?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CardInfo = {
  __typename?: 'CardInfo';
  primaryId: Scalars['String'];
  secondaryId?: Maybe<Scalars['String']>;
  repetition: Scalars['Int'];
  interval: Scalars['Int'];
  efactor: Scalars['Float'];
  dueDate: Scalars['DateTime'];
};

export type ContentRelation = {
  __typename?: 'ContentRelation';
  topic: Scalars['String'];
  relatesTo: Scalars['String'];
};

export type CountObjects = {
  __typename?: 'CountObjects';
  connections: MapComponentStats;
  nodes: MapComponentStats;
  nodesWithContent: MapComponentStats;
};

export type Course = {
  __typename?: 'Course';
  id: Scalars['ID'];
  name: Scalars['String'];
  access: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  thumbnailURL: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  mapId: Scalars['ID'];
  map?: Maybe<Map>;
  tags: Array<Scalars['String']>;
  suspend?: Maybe<CourseSuspend>;
  /** My reaction for the course */
  viewerReaction?: Maybe<Reaction>;
  reactionSummary?: Maybe<ReactionSummary>;
  allowSaveAs?: Maybe<Scalars['Boolean']>;
  allowPersonalisation?: Maybe<Scalars['Boolean']>;
  requirePayment?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<CoursePayment>;
};

export type CourseInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  access: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  thumbnailURL?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  mapId: Scalars['ID'];
  tags?: Maybe<Array<Scalars['String']>>;
  allowSaveAs?: Maybe<Scalars['Boolean']>;
  allowPersonalisation?: Maybe<Scalars['Boolean']>;
  requirePayment?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<CoursePaymentInput>;
};

export type CourseItem = CourseModule | LessonItem | Quiz | RecallItem | FlashcardItem;

export type CourseItemMoveInput = {
  courseItemId: Scalars['ID'];
  moduleId: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
};

export type CourseItemOrderInput = {
  ids: Array<Scalars['ID']>;
};

export type CourseModule = {
  __typename?: 'CourseModule';
  id: Scalars['ID'];
  name: Scalars['String'];
  items?: Maybe<Array<ModuleItem>>;
};

export type CourseModuleInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CourseModuleItemOrderInput = {
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']>;
};

export type CoursePayment = {
  __typename?: 'CoursePayment';
  amount: Scalars['Float'];
  currency: Currency;
};

export type CoursePaymentInput = {
  amount: Scalars['Float'];
  currency: Currency;
};

export type CoursePaymentUpdateInput = {
  id: Scalars['ID'];
  requirePayment: Scalars['Boolean'];
  payment: CoursePaymentInput;
};

export type CoursePrice = {
  __typename?: 'CoursePrice';
  amount: Scalars['Float'];
  currency: Currency;
};

export type CourseSuspend = {
  __typename?: 'CourseSuspend';
  date: Scalars['DateTime'];
  reason: Scalars['String'];
  suspendedBy: Scalars['ID'];
};

/** Keep this enum in sync with supportedCurrencies in getCurrency.ts */
export enum Currency {
  Usd = 'USD',
  Gbp = 'GBP',
  Eur = 'EUR',
  Nzd = 'NZD',
  Aud = 'AUD'
}



export type DeleteResult = {
  __typename?: 'DeleteResult';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Boolean']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type Email = {
  __typename?: 'Email';
  id: Scalars['ID'];
  type: Scalars['String'];
  status: EmailStatus;
  recipients?: Maybe<Array<Scalars['String']>>;
  /** When email first queue */
  queued: Scalars['DateTime'];
  /** When email was successfully sent */
  sent?: Maybe<Scalars['DateTime']>;
  /** User who sent the email */
  userId?: Maybe<Scalars['ID']>;
  userEmail?: Maybe<Scalars['String']>;
  /** MessageId for email recieved by AWS */
  awsMessagId?: Maybe<Scalars['String']>;
  /** Last error message */
  error?: Maybe<Scalars['String']>;
  attempt?: Maybe<Scalars['Int']>;
};

export type EmailQueue = {
  __typename?: 'EmailQueue';
  pageInfo: PageInfo;
  emails?: Maybe<Array<Maybe<Email>>>;
};

/** Valid email status values */
export enum EmailStatus {
  /** Pending email */
  Pending = 'pending',
  /** Successfully sent email */
  Sent = 'sent',
  /** Error sending emails, will retry */
  Error = 'error',
  /** Failed to send email after retries */
  Failed = 'failed',
  /** Email was not sent */
  Suppressed = 'suppressed'
}

export type ExtractedTopic = {
  __typename?: 'ExtractedTopic';
  topic: Scalars['String'];
  detail: Scalars['String'];
};

export type FeaturesUsed = {
  __typename?: 'FeaturesUsed';
  lesson: Scalars['Int'];
  presentation: Scalars['Int'];
  truth: Scalars['Int'];
  levels: Scalars['Int'];
  tags: Scalars['Int'];
  neighbourhoods: Scalars['Int'];
};

export type FlashcardInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  tags: Array<Scalars['ID']>;
};

export type FlashcardItem = {
  __typename?: 'FlashcardItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  tags: Array<Scalars['ID']>;
};

export type FlashcardResponseInput = {
  mapId: Scalars['ID'];
  response: Scalars['Int'];
  primaryId: Scalars['String'];
  secondaryId?: Maybe<Scalars['String']>;
};

export type GeneralResult = {
  __typename?: 'GeneralResult';
  success: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type GeneratedAnswer = {
  __typename?: 'GeneratedAnswer';
  question?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  answerPoints: Array<AnswerPoint>;
};

export type GroupInvitation = {
  __typename?: 'GroupInvitation';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user: User;
  role: GroupRole;
};

export type GroupResource = {
  __typename?: 'GroupResource';
  id: Scalars['ID'];
  mapId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: ResourceType;
  created: WriteDetails;
};

export enum GroupRole {
  Member = 'Member',
  Admin = 'Admin'
}


export type LabelInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LessonInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type LessonItem = {
  __typename?: 'LessonItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  presentationId: Scalars['ID'];
};

export type LessonProgress = {
  __typename?: 'LessonProgress';
  lessonId: Scalars['ID'];
  previousWatchedTime?: Maybe<Scalars['Float']>;
  longestWatchedTime?: Maybe<Scalars['Float']>;
};

export type LessonProgressInput = {
  mapId: Scalars['ID'];
  lessonId: Scalars['ID'];
  previousWatchedTime?: Maybe<Scalars['Float']>;
  longestWatchedTime?: Maybe<Scalars['Float']>;
};

export type LessonUpdateResult = {
  __typename?: 'LessonUpdateResult';
  map: Map;
  lessonId: Scalars['ID'];
};

export type Map = {
  __typename?: 'Map';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  courseItems: Array<CourseItem>;
  updated?: Maybe<WriteDetails>;
  created?: Maybe<WriteDetails>;
  questions: Array<Question>;
  presentations: Array<Presentation>;
  tags: Array<Tag>;
};

export type MapComponentStats = {
  __typename?: 'MapComponentStats';
  median: Scalars['Float'];
  maximum: Scalars['Float'];
};

export type MapDayUsage = {
  __typename?: 'MapDayUsage';
  date?: Maybe<Scalars['DateTime']>;
  totalUses?: Maybe<Scalars['Float']>;
  user?: Maybe<UsageSummary>;
  anonymous?: Maybe<UsageSummary>;
};

export type MapLabel = {
  __typename?: 'MapLabel';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MapProgress = {
  __typename?: 'MapProgress';
  id: Scalars['ID'];
  mapId: Scalars['ID'];
  user: UserSummary;
  lessons: Array<LessonProgress>;
};

export type MapShare = {
  __typename?: 'MapShare';
  id: Scalars['ID'];
  user?: Maybe<User>;
  userGroup?: Maybe<UserGroup>;
  access?: Maybe<Scalars['Int']>;
};

/** Summary meta data of a map */
export type MapSummary = {
  __typename?: 'MapSummary';
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  owner: User;
  courseItems: Array<CourseItem>;
  updated?: Maybe<WriteDetails>;
  created?: Maybe<WriteDetails>;
  userTags: Array<Scalars['String']>;
  /** @deprecated Use userTags. */
  tags: Array<Scalars['String']>;
  labels: Array<Scalars['ID']>;
};

export type MapUsage = {
  __typename?: 'MapUsage';
  id: Scalars['ID'];
  mapId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  dayUsage?: Maybe<Array<Maybe<MapDayUsage>>>;
};

export type MarketingList = {
  __typename?: 'MarketingList';
  id: Scalars['ID'];
  name: Scalars['String'];
  memberCount: Scalars['Float'];
};

export type ModuleItem = LessonItem | Quiz | RecallItem | FlashcardItem;

export type Mutation = {
  __typename?: 'Mutation';
  updateOrganization?: Maybe<Organization>;
  updateLessonProgress?: Maybe<MapProgress>;
  /** Resend emails */
  resendEmails?: Maybe<UpdateResult>;
  /**
   * Purge old sent and failed emails
   * returns number purged
   */
  purgeEmails?: Maybe<DeleteResult>;
  /** Create or update a reaction */
  updateReaction: Course;
  /** Remove a reaction */
  removeReaction: Course;
  synchronizeMarketingUsers: GeneralResult;
  resendInvite?: Maybe<Scalars['Boolean']>;
  sendFeedback?: Maybe<Scalars['Boolean']>;
  /**
   * Create a user with password for testing, admin only functionality.
   * Creating normal user should go through the invite process
   */
  createTestUser?: Maybe<User>;
  /** Update a user */
  updateUser?: Maybe<User>;
  /** Partially update a user */
  partialUpdateUser?: Maybe<User>;
  /** Delete a user. admin only functionality. */
  deleteUser?: Maybe<User>;
  /** Mark current user has agreed the latest user terms */
  agreeUserTerms?: Maybe<User>;
  /** Set flag on a user */
  setUserFlag?: Maybe<User>;
  /** Mark the suggestion as complete for current user */
  completeAchievement?: Maybe<User>;
  /** Create a label for the current user */
  createLabel: User;
  /** Rename a label for the current user */
  renameLabel: User;
  /** Move maps into a label */
  moveMapsToLabel: Array<MapSummary>;
  /** Delete a label for the current user */
  deleteLabel: User;
  updateUserGroup?: Maybe<UserGroup>;
  deleteUserGroup: Scalars['ID'];
  /**
   * Remove a teacher from the user group
   * Remove the invitation to the user if it exists
   */
  removeTeacher?: Maybe<UserGroup>;
  /** Remove students from the user group */
  removeStudents?: Maybe<UserGroup>;
  /** Archive the user group */
  archiveUserGroup: Scalars['ID'];
  /** Restore an archived user group */
  restoreUserGroup: Scalars['ID'];
  /** Add a resource to the user group */
  addUserGroupResource?: Maybe<UserGroup>;
  /** Remove a resource from the user group */
  removeUserGroupResource?: Maybe<UserGroup>;
  generateUserGroupAccessCode?: Maybe<UserGroup>;
  revokeUserGroupAccessCode?: Maybe<UserGroup>;
  /** Join a user group */
  joinUserGroupByCode: UserGroup;
  /** Leave a user group */
  leaveUserGroup: Scalars['ID'];
  /** Invite users to join the group by email */
  inviteGroupUsers: UserGroup;
  /** Update user flashcard response */
  updateUserCardResponse: UserFlashcard;
  /** Remove a course item */
  removeCourseItem: Map;
  /** Convert presentation to lesson item */
  convertPresentationToLesson: LessonUpdateResult;
  /** Update a course module */
  updateCourseModule?: Maybe<Map>;
  /** Update a lesson item for a map */
  updateLesson?: Maybe<LessonUpdateResult>;
  /** Update a quiz item for a map */
  updateQuiz?: Maybe<Map>;
  /** Update a recall item for a map */
  updateRecall?: Maybe<Map>;
  /** Update a flashcard item for a map */
  updateFlashcard?: Maybe<Map>;
  /** Reorder a course items */
  reorderCourseItems?: Maybe<Map>;
  /** Reorder a course module items */
  reorderCourseModuleItems?: Maybe<Map>;
  /** Move a course item into a module */
  moveCourseItemToModule?: Maybe<Map>;
  /** Move a course item out of a module */
  moveCourseItemOutOfModule?: Maybe<Map>;
  /** Reorder presentations */
  reorderPresentations?: Maybe<Map>;
  /** Add a user tag to Maps */
  addUserTagToMaps: Array<MapSummary>;
  /** Add a user tag to a Map */
  addUserTagToMap?: Maybe<MapSummary>;
  /** Remove a user tag from a map */
  removeUserTag?: Maybe<MapSummary>;
  /** Remove a user tag from Maps */
  removeUserTagFromMaps: Array<MapSummary>;
  /** Update course setting */
  updateCourse?: Maybe<Course>;
  /** Enrol a course */
  enrolCourse?: Maybe<User>;
  /** Unenrol a course */
  unenrolCourse?: Maybe<User>;
  /** Suspend a course */
  suspendCourse: Course;
  /** Unsuspend a course */
  unsuspendCourse: Course;
  /** Update payments system */
  updatePaymentsSystem?: Maybe<Course>;
  /** Update trial statistics */
  updateTrialStat?: Maybe<TrialStat>;
  /** Update trial statistics note */
  updateTrialStatNote?: Maybe<TrialStat>;
};


export type MutationUpdateOrganizationArgs = {
  organization: OrganizationInput;
};


export type MutationUpdateLessonProgressArgs = {
  lessonProgress: LessonProgressInput;
};


export type MutationResendEmailsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateReactionArgs = {
  reaction?: Maybe<ReactionInput>;
};


export type MutationRemoveReactionArgs = {
  reactionId: Scalars['ID'];
};


export type MutationSynchronizeMarketingUsersArgs = {
  listId: Scalars['ID'];
};


export type MutationResendInviteArgs = {
  userId: Scalars['ID'];
};


export type MutationSendFeedbackArgs = {
  feedback: Scalars['String'];
};


export type MutationCreateTestUserArgs = {
  user: UserAndPasswordInput;
};


export type MutationUpdateUserArgs = {
  user: UserInput;
};


export type MutationPartialUpdateUserArgs = {
  userUpdate: UserUpdate;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationSetUserFlagArgs = {
  flag: Scalars['String'];
};


export type MutationCompleteAchievementArgs = {
  suggestionId: Scalars['String'];
};


export type MutationCreateLabelArgs = {
  name: Scalars['String'];
};


export type MutationRenameLabelArgs = {
  label: LabelInput;
};


export type MutationMoveMapsToLabelArgs = {
  mapIds: Array<Scalars['ID']>;
  labelId: Scalars['ID'];
};


export type MutationDeleteLabelArgs = {
  labelId: Scalars['ID'];
};


export type MutationUpdateUserGroupArgs = {
  userGroup: UserGroupInput;
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTeacherArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveStudentsArgs = {
  groupId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};


export type MutationArchiveUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserGroupResourceArgs = {
  groupId: Scalars['ID'];
  resource: UserGroupResourceInput;
};


export type MutationRemoveUserGroupResourceArgs = {
  groupId: Scalars['ID'];
  mapId: Scalars['ID'];
  type: ResourceType;
};


export type MutationGenerateUserGroupAccessCodeArgs = {
  id: Scalars['ID'];
};


export type MutationRevokeUserGroupAccessCodeArgs = {
  id: Scalars['ID'];
};


export type MutationJoinUserGroupByCodeArgs = {
  accessCode: Scalars['String'];
};


export type MutationLeaveUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationInviteGroupUsersArgs = {
  groupId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
  role: GroupRole;
};


export type MutationUpdateUserCardResponseArgs = {
  userResponse: FlashcardResponseInput;
};


export type MutationRemoveCourseItemArgs = {
  mapId: Scalars['ID'];
  courseItemId: Scalars['ID'];
};


export type MutationConvertPresentationToLessonArgs = {
  mapId: Scalars['ID'];
  presentationItemId: Scalars['ID'];
};


export type MutationUpdateCourseModuleArgs = {
  mapId: Scalars['ID'];
  module: CourseModuleInput;
};


export type MutationUpdateLessonArgs = {
  mapId: Scalars['ID'];
  moduleId?: Maybe<Scalars['ID']>;
  lessonItem: LessonInput;
};


export type MutationUpdateQuizArgs = {
  mapId: Scalars['ID'];
  moduleId?: Maybe<Scalars['ID']>;
  quizItem: QuizInput;
};


export type MutationUpdateRecallArgs = {
  mapId: Scalars['ID'];
  moduleId?: Maybe<Scalars['ID']>;
  recallItem: RecallInput;
};


export type MutationUpdateFlashcardArgs = {
  mapId: Scalars['ID'];
  moduleId?: Maybe<Scalars['ID']>;
  flashcardItem: FlashcardInput;
};


export type MutationReorderCourseItemsArgs = {
  mapId: Scalars['ID'];
  itemOrder: CourseItemOrderInput;
};


export type MutationReorderCourseModuleItemsArgs = {
  mapId: Scalars['ID'];
  itemOrder: CourseModuleItemOrderInput;
};


export type MutationMoveCourseItemToModuleArgs = {
  mapId: Scalars['ID'];
  moveInput: CourseItemMoveInput;
};


export type MutationMoveCourseItemOutOfModuleArgs = {
  mapId: Scalars['ID'];
  itemId: Scalars['ID'];
};


export type MutationReorderPresentationsArgs = {
  mapId: Scalars['ID'];
  itemOrder: PresentationOrderInput;
};


export type MutationAddUserTagToMapsArgs = {
  mapIds: Array<Scalars['ID']>;
  tag: Scalars['String'];
};


export type MutationAddUserTagToMapArgs = {
  mapId: Scalars['ID'];
  tag: Scalars['String'];
};


export type MutationRemoveUserTagArgs = {
  mapId: Scalars['ID'];
  tag: Scalars['String'];
};


export type MutationRemoveUserTagFromMapsArgs = {
  mapIds: Array<Scalars['ID']>;
  tag: Scalars['String'];
};


export type MutationUpdateCourseArgs = {
  course: CourseInput;
};


export type MutationEnrolCourseArgs = {
  courseId: Scalars['ID'];
};


export type MutationUnenrolCourseArgs = {
  courseId: Scalars['ID'];
};


export type MutationSuspendCourseArgs = {
  courseId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationUnsuspendCourseArgs = {
  courseId: Scalars['ID'];
};


export type MutationUpdatePaymentsSystemArgs = {
  course: CoursePaymentUpdateInput;
};


export type MutationUpdateTrialStatArgs = {
  trialStat: TrialStatInput;
};


export type MutationUpdateTrialStatNoteArgs = {
  trialStatNote: TrialStatNoteInput;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['String'];
};

export type OrganizationInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
};

/** The page info object for pagination */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Total number of documents available */
  totalDocs?: Maybe<Scalars['Int']>;
  /** Total number of pages available */
  totalPages?: Maybe<Scalars['Int']>;
  /** True if there is another page of results */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** True if there is a previous page of results */
  hasPrevPage?: Maybe<Scalars['Boolean']>;
  /** Limit used for this query */
  limit?: Maybe<Scalars['Int']>;
  /** Curent page number */
  page?: Maybe<Scalars['Int']>;
};

export type Presentation = {
  __typename?: 'Presentation';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['Int'];
  videos: Array<PresentationVideo>;
  media?: Maybe<PresentationMedia>;
};

export type PresentationMedia = {
  __typename?: 'PresentationMedia';
  name?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type PresentationOrderInput = {
  ids: Array<Scalars['ID']>;
};

/**
 * PresentationVideo does not have an equivalent MongoDB schema
 * as it is currently stored as json in a string.
 * All properties must therefore be optional
 */
export type PresentationVideo = {
  __typename?: 'PresentationVideo';
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type PrivacyControl = {
  __typename?: 'PrivacyControl';
  resource: Scalars['String'];
  level: Scalars['String'];
};

export type PrivacyControlInput = {
  resource: Scalars['String'];
  level: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  organizations?: Maybe<Array<Maybe<Organization>>>;
  /** Get question answers for all users */
  getAllAnswers?: Maybe<Array<Maybe<AnswerAttempt>>>;
  /** Get question answers for user group */
  getGroupUsersAnswers?: Maybe<Array<Maybe<AnswerAttempt>>>;
  /** Get my question answers for the map */
  mapAnswers?: Maybe<Array<AnswerAttempt>>;
  mapProgress?: Maybe<MapProgress>;
  mapProgressAllUsers?: Maybe<Array<Maybe<MapProgress>>>;
  mapProgressGroupUsers?: Maybe<Array<Maybe<MapProgress>>>;
  mapShares?: Maybe<Array<MapShare>>;
  /** Paginated list of emails in the queue */
  emailQueue?: Maybe<EmailQueue>;
  /** Map Usage */
  mapUsage?: Maybe<MapUsage>;
  mapUsageSummary?: Maybe<Array<Maybe<MapUsage>>>;
  /** Suggestions for current user */
  suggestions?: Maybe<Array<Suggestion>>;
  /** Media Uuid to url */
  mediaUrl?: Maybe<Scalars['String']>;
  /** Email Marketing Lists */
  marketingLists?: Maybe<Array<MarketingList>>;
  /** Return current user */
  user?: Maybe<User>;
  /** Get the user summary by given username */
  getUserSummary?: Maybe<UserSummary>;
  /** Get state summary of maps created by the user */
  userMapSummary?: Maybe<UserMapSummary>;
  /** Get all users for an organization */
  users?: Maybe<Array<Maybe<User>>>;
  /** Get user course access */
  userCourseAccess: UserCourseAccess;
  /** Get user group by ID */
  userGroup: UserGroup;
  /** Get all user groups for an organization */
  userGroups?: Maybe<Array<UserGroup>>;
  /** Get all user groups that I am in */
  getMyUserGroups: Array<UserGroup>;
  /** Get all user groups that I'm the admin of */
  getUserGroupsOwnedByMe: Array<UserGroup>;
  /** Get flashcard responses for the current user */
  flashcardResults?: Maybe<UserFlashcard>;
  map?: Maybe<Map>;
  /** Get all available maps for the current user */
  maps?: Maybe<Array<MapSummary>>;
  /** Get all maps that I can share to other users */
  getMapsForSharing?: Maybe<Array<MapSummary>>;
  /** Get recently opened maps */
  recentMaps?: Maybe<Array<RecentMap>>;
  /** Get course detail */
  course?: Maybe<Course>;
  /** Get all public available courses */
  courses?: Maybe<Array<Course>>;
  /** Search courses with the given query string */
  searchCourses: Array<Course>;
  /** Get course pricing */
  coursePricing: Array<CoursePrice>;
  generateAnswer: GeneratedAnswer;
  expandTopic: TopicExpansion;
  relatedContent: Array<Maybe<ContentRelation>>;
  extractTopics: Array<Maybe<ExtractedTopic>>;
  trialStats: Array<Maybe<TrialStat>>;
  trialStatUser: TrialStatUser;
};


export type QueryGetAllAnswersArgs = {
  mapId?: Maybe<Scalars['ID']>;
};


export type QueryGetGroupUsersAnswersArgs = {
  mapId: Scalars['ID'];
  userGroupId: Scalars['ID'];
};


export type QueryMapAnswersArgs = {
  mapId?: Maybe<Scalars['ID']>;
};


export type QueryMapProgressArgs = {
  mapId: Scalars['ID'];
};


export type QueryMapProgressAllUsersArgs = {
  mapId: Scalars['ID'];
};


export type QueryMapProgressGroupUsersArgs = {
  mapId: Scalars['ID'];
  userGroupId: Scalars['ID'];
};


export type QueryMapSharesArgs = {
  mapId: Scalars['ID'];
};


export type QueryEmailQueueArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  sender?: Maybe<Scalars['String']>;
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryMapUsageArgs = {
  mapId: Scalars['ID'];
};


export type QueryMapUsageSummaryArgs = {
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};


export type QueryMediaUrlArgs = {
  mediaUuid: Scalars['String'];
};


export type QueryGetUserSummaryArgs = {
  username: Scalars['String'];
};


export type QueryUserMapSummaryArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type QueryUserCourseAccessArgs = {
  courseId: Scalars['ID'];
};


export type QueryUserGroupArgs = {
  id: Scalars['ID'];
};


export type QueryUserGroupsArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type QueryGetMyUserGroupsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
};


export type QueryFlashcardResultsArgs = {
  mapId: Scalars['ID'];
};


export type QueryMapArgs = {
  mapId: Scalars['ID'];
};


export type QueryRecentMapsArgs = {
  userAction?: Maybe<Scalars['String']>;
};


export type QueryCourseArgs = {
  courseId?: Maybe<Scalars['ID']>;
  mapId?: Maybe<Scalars['ID']>;
};


export type QueryCoursesArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
};


export type QuerySearchCoursesArgs = {
  query: Scalars['String'];
};


export type QueryCoursePricingArgs = {
  courseId: Scalars['ID'];
};


export type QueryGenerateAnswerArgs = {
  question?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};


export type QueryExpandTopicArgs = {
  topic?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};


export type QueryRelatedContentArgs = {
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
};


export type QueryExtractTopicsArgs = {
  content: Scalars['String'];
};


export type QueryTrialStatsArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryTrialStatUserArgs = {
  userId: Scalars['ID'];
};

export type Question = {
  __typename?: 'Question';
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Replaced with calculated field (Question X) */
  name: Scalars['String'];
  lessonId: Scalars['String'];
};

export type QuestionSetting = {
  __typename?: 'QuestionSetting';
  enableRedo?: Maybe<Scalars['Boolean']>;
  answerMode?: Maybe<AnswerMode>;
};

export type QuestionSettingInput = {
  enableRedo?: Maybe<Scalars['Boolean']>;
  answerMode?: Maybe<AnswerMode>;
};

export type Quiz = {
  __typename?: 'Quiz';
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<Scalars['ID']>;
  settings?: Maybe<QuestionSetting>;
};

export type QuizInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  questions?: Maybe<Array<Scalars['ID']>>;
  settings?: Maybe<QuestionSettingInput>;
};

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID'];
  targetId: Scalars['ID'];
  userId: Scalars['ID'];
  user: User;
  reactionType: Scalars['String'];
};

export type ReactionInput = {
  targetId: Scalars['ID'];
};

export type ReactionSummary = {
  __typename?: 'ReactionSummary';
  likeCount: Scalars['Int'];
};

export type RecallInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  tags: Array<Scalars['ID']>;
};

export type RecallItem = {
  __typename?: 'RecallItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  tags: Array<Scalars['ID']>;
};

export type RecentMap = {
  __typename?: 'RecentMap';
  id?: Maybe<Scalars['ID']>;
  mapId: Scalars['ID'];
  courseId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  userAction?: Maybe<Scalars['String']>;
};

export enum ResourceType {
  Course = 'Course',
  Map = 'Map'
}

export type Suggestion = {
  __typename?: 'Suggestion';
  id: Scalars['ID'];
  description: Scalars['String'];
  actions?: Maybe<Array<SuggestionAction>>;
};

export type SuggestionAction = {
  __typename?: 'SuggestionAction';
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  important?: Maybe<Scalars['Boolean']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  description: Scalars['String'];
  selected?: Maybe<Scalars['Boolean']>;
};

export type TopicExpansion = {
  __typename?: 'TopicExpansion';
  topic: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  detail: Scalars['String'];
};

export type TrialData = {
  __typename?: 'TrialData';
  name: Scalars['String'];
  values: Array<Scalars['Float']>;
};

export type TrialDataInput = {
  name: Scalars['String'];
  values: Array<Scalars['Float']>;
};

export type TrialStat = {
  __typename?: 'TrialStat';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  trialType: Scalars['String'];
  date: Scalars['DateTime'];
  statistics: Array<TrialStatistic>;
  data: Array<TrialData>;
  note?: Maybe<Scalars['String']>;
};

export type TrialStatInput = {
  id?: Maybe<Scalars['ID']>;
  trialType: Scalars['String'];
  statistics?: Maybe<Array<TrialStatisticInput>>;
  data?: Maybe<Array<TrialDataInput>>;
};

export type TrialStatistic = {
  __typename?: 'TrialStatistic';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type TrialStatisticInput = {
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type TrialStatNoteInput = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type TrialStatUser = {
  __typename?: 'TrialStatUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Boolean']>;
  nModified?: Maybe<Scalars['Int']>;
};


export type UsageSummary = {
  __typename?: 'UsageSummary';
  loads?: Maybe<Scalars['Float']>;
  courseLoads?: Maybe<Scalars['Float']>;
  shares?: Maybe<Scalars['Float']>;
  updates?: Maybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  registered: Scalars['Date'];
  preRegistered?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<Scalars['ID']>;
  courses?: Maybe<Array<Scalars['ID']>>;
  flags?: Maybe<Array<Scalars['String']>>;
  unsubscribedEmails?: Maybe<Array<Scalars['String']>>;
  achievement?: Maybe<Array<Achievement>>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  mapLabels: Array<MapLabel>;
  privacyControls?: Maybe<Array<PrivacyControl>>;
};

export type UserAndPasswordInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<Scalars['ID']>;
};

export type UserCourseAccess = {
  __typename?: 'UserCourseAccess';
  userId?: Maybe<Scalars['ID']>;
  courseId: Scalars['String'];
  access: Access;
};

export type UserFlashcard = {
  __typename?: 'UserFlashcard';
  id: Scalars['ID'];
  cards: Array<CardInfo>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Users with admin permissions for the user group */
  adminMembers: Array<User>;
  /** Users without admin permissions for the user group */
  students: Array<User>;
  resources: Array<GroupResource>;
  accessCode?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  studentCount?: Maybe<Scalars['Int']>;
  resourceCount?: Maybe<Scalars['Int']>;
  invitations: Array<GroupInvitation>;
};

export type UserGroupInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UserGroupResourceInput = {
  mapId: Scalars['ID'];
  type: ResourceType;
  access?: Maybe<Scalars['Int']>;
};

export type UserInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<Scalars['ID']>;
};

export type UserMapSummary = {
  __typename?: 'UserMapSummary';
  created: Scalars['Int'];
  shared: Scalars['Int'];
  available: Scalars['Int'];
  featuresUsed: FeaturesUsed;
  counts: CountObjects;
};

export type UserSummary = {
  __typename?: 'UserSummary';
  id: Scalars['ID'];
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserUpdate = {
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  privacyControls?: Maybe<Array<PrivacyControlInput>>;
};

export type WriteDetails = {
  __typename?: 'WriteDetails';
  userId: Scalars['String'];
  user?: Maybe<User>;
  date: Scalars['DateTime'];
};

