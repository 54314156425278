import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import SharePanel from "../SharePanel";
import URLPanel from "../URLPanel";

type Props = {
  onClose: () => void;
  url?: string | undefined;
};

const ShareModal: React.FC<Props> = ({ onClose, url }) => {
  let link = window.location.href;
  if (url !== undefined) {
    link = url;
  }
  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <SharePanel url={link} />
        <URLPanel value={link} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
