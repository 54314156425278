import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, List, ListItemText, withStyles } from "@material-ui/core";
import MuiLinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RequiredLessonUserType } from "../../../../../src/@types/courseItem";
import { ExtendedTooltip } from "../../../ExtendedTooltip/ExtendedTooltip";
import { AnswerAttempt, CourseModule, Map, MapProgress } from "../../../../../generated/graphql";
import ModuleItem from "./ModuleItem";
import { ModuleProgress } from "./moduleProgress";

const TitleContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
});

const LinearProgress = withStyles({
  root: {
    height: 14,
    width: 70,
    borderRadius: 7,
  },
  colorPrimary: {
    backgroundColor: "#c0c0c0",
  },
  bar: {
    backgroundColor: "green",
  },
})(MuiLinearProgress);

type Props = {
  expanded?: boolean;
  courseModule: CourseModule;
  courseId?: string;
  map: Map;
  mapProgress?: MapProgress | null;
  mapAnswers?: AnswerAttempt[];
  moduleProgress?: ModuleProgress;
  flashcardProgress?: Record<string, { correct: number; attempted: number }> | undefined;
  onToggleExpand: (moduleId: string) => void;
  getLessonUserType: RequiredLessonUserType;
};

const CourseModuleItem: React.FC<Props> = ({
  expanded,
  onToggleExpand,
  courseModule,
  map,
  courseId,
  mapProgress,
  mapAnswers,
  moduleProgress,
  flashcardProgress,
  getLessonUserType,
}) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={() => onToggleExpand(courseModule.id)}
      data-testid="course-module-accordion"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <ListItemText
          primary={
            <TitleContainer>
              <div>{courseModule.name}</div>
              {moduleProgress && moduleProgress.total && (
                <ExtendedTooltip
                  data={{
                    title: "Progress",
                    detail:
                      moduleProgress.completed === 0
                        ? "Not started"
                        : `Completed ${moduleProgress.completed} of ${moduleProgress.total}`,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={(moduleProgress.completed / moduleProgress.total) * 100}
                  />
                </ExtendedTooltip>
              )}
            </TitleContainer>
          }
        />
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {courseModule.items && (
          <List style={{ padding: 0, width: "100%" }}>
            {courseModule.items.map(item => {
              return (
                <ModuleItem
                  key={item.id}
                  courseItem={item}
                  map={map}
                  courseId={courseId}
                  mapAnswers={mapAnswers}
                  mapProgress={mapProgress}
                  flashcardProgress={flashcardProgress}
                  getLessonUserType={getLessonUserType}
                />
              );
            })}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CourseModuleItem;
