import { isEmpty } from "lodash";

function getDuration(
  presentation:
    | {
        videos?: { duration?: number | null | undefined }[] | null;
        media?: ({ url?: string | undefined | null; duration?: number | undefined | null }) | null;
      }
    | undefined
): number | undefined | null {
  if (!presentation) return null;
  if (presentation.media && presentation.media.url) return presentation.media.duration;
  if (presentation.videos && !isEmpty(presentation.videos)) return presentation.videos[0].duration;
  return null;
}

export const PresentationUtils = {
  getDuration,
};
