import React from "react";
import { RequiredLessonUserType } from "../../../../src/@types/courseItem";
import { AccessRight } from "../../../../constant/accessRights";
import { Course, ModuleItem, Presentation } from "../../../../generated/graphql";
import { isPublicCourse } from "../../../../utils/courseUtils";
import CourseActions from "./CourseActions";
import CourseDescription from "./CourseDescription";

type SummaryActionProps = {
  course: Course;
  hasLessons: boolean;
  hasPresentations: boolean;
  standardPresentations: Presentation[];
  nextCourseItem?: ModuleItem;
  accessRight?: AccessRight;
  hasAdminRights?: boolean;
  hasFlashCards?: boolean;
  getLessonUserType: RequiredLessonUserType;
};
const CourseSummaryActions: React.FC<SummaryActionProps> = ({
  course,
  hasLessons,
  hasPresentations,
  standardPresentations,
  nextCourseItem,
  accessRight = AccessRight.VIEW,
  hasAdminRights,
  hasFlashCards,
  getLessonUserType,
}) => {
  return (
    <>
      <CourseDescription course={course} />
      <CourseActions
        courseId={isPublicCourse(course) ? course.id : undefined}
        mapId={course.mapId}
        nextCourseItem={nextCourseItem}
        presentationId={hasPresentations ? standardPresentations[0].id : undefined}
        title={course.name}
        hasLessons={hasLessons}
        accessRight={accessRight}
        hasAdminRights={hasAdminRights}
        hasFlashCards={hasFlashCards}
        getLessonUserType={getLessonUserType}
      />
    </>
  );
};

export default CourseSummaryActions;
