import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FileCopy from "@material-ui/icons/FileCopy";
import copy from "copy-to-clipboard";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    copySuccess: {
      width: "100%",
      height: 30,
      opacity: 0,
      transition: "opacity 0.5s ease-in-out",
    },
    active: {
      opacity: 1,
    },
  })
);

type Props = {
  value: string;
};

const URLPanel: React.FC<Props> = ({ value }) => {
  const classes = useStyles();
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const handleCopy = () => {
    copy(value);
    setShowCopySuccess(true);
  };
  return (
    <>
      <Paper className={classes.root}>
        <a href={value} style={{ padding: 16, overflow: "auto" }}>
          {value}
        </a>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton className={classes.iconButton} aria-label="copy" onClick={handleCopy}>
          <FileCopy />
        </IconButton>
      </Paper>

      <div className={`${classes.copySuccess} ${showCopySuccess && classes.active}`}>
        {showCopySuccess && (
          <Typography variant="body1" align="center">
            Link copied to clipboard
          </Typography>
        )}
      </div>
    </>
  );
};
export default URLPanel;
