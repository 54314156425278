import NoSleep from "nosleep.js";

let noSleep: NoSleep | undefined;

// This will only work if called from user input event handler
export const preventSleep = (): void => {
  if (!noSleep) {
    noSleep = new NoSleep();
  }
  if (!noSleep.isEnabled) {
    const noSleepLocal = noSleep;
    const enableNoSleep = async () => {
      try {
        await noSleepLocal.enable();
      } catch (e) {
        console.error("Unable to prevent sleep", e);
      }
    };
    enableNoSleep();
  } else {
    console.log("'No sleep' is already enabled");
  }
};

export const enableSleep = (): void => {
  if (noSleep && noSleep.isEnabled) {
    noSleep.disable();
  }
};
