import React, { useContext } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import HistoryContext from "../../../context/HistoryContext";
import { UserType } from "../../../../constant/userType";
import _Button from "../../Button";

const Button = styled(_Button)`
  margin: auto;
  min-width: 205px;
`;

export const PaymentOutcome: React.FC<{ outcome: "paid" | "cancelled" }> = ({ outcome }) => {
  const history = useContext(HistoryContext);

  const onOutcomeAcknowledged = () => {
    history.push(window.location.pathname, UserType.AllUsers);
  };

  return (
    <Button
      fullWidth={false}
      height="medium"
      onClick={() => onOutcomeAcknowledged()}
      color="primary"
      style={{ pointerEvents: "all" }}
    >
      <Typography variant="body1" style={{ margin: "0 16px" }} align="center">
        {outcome === "paid" ? "Payment confirmed" : "Payment cancelled"}
      </Typography>
    </Button>
  );
};
