import React, { CSSProperties } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  WeiboShareButton,
  WeiboIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

type Props = {
  url: string;
  beforeShareClick?: () => void;
};

const HashTag = "akomaps";
const ShareButtonStyle: CSSProperties = { padding: 8 };
const RedditIconStyle: CSSProperties = { fill: "#FF5700" };

const SharePanel: React.FC<Props> = ({ url, beforeShareClick }) => {
  return (
    <div style={{ paddingBottom: 16 }}>
      <EmailShareButton url={url} subject="Ako Maps" style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <EmailIcon size={56} round />
      </EmailShareButton>
      <FacebookShareButton url={url} hashtag={HashTag} style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <FacebookIcon size={56} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} hashtags={[HashTag]} style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <TwitterIcon size={56} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <LinkedinIcon size={56} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <WhatsappIcon size={56} round />
      </WhatsappShareButton>
      <WeiboShareButton url={url} style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <WeiboIcon size={56} round />
      </WeiboShareButton>
      <RedditShareButton url={url} style={ShareButtonStyle} beforeOnClick={beforeShareClick}>
        <RedditIcon size={56} round bgStyle={RedditIconStyle} />
      </RedditShareButton>
    </div>
  );
};

export default SharePanel;
