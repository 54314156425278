import React, { memo, useMemo } from "react";
import { ExtendedTooltip } from "../../../ExtendedTooltip/ExtendedTooltip";
import { LessonItem } from "../../../../../generated/graphql";
import useNavigation from "../../../../hooks/useNavigation";
import LessonItemSummary from "./LessonItemSummary";
import { CourseItemListItem } from "./CourseItemListItem";
import { RequiredLessonUserType } from "../../../../../src/@types/courseItem";

type Props = {
  courseId: string | undefined;
  mapId: string;
  lesson: LessonItem;
  duration?: number | null;
  lessonProgress: number;
  getLessonUserType: RequiredLessonUserType;
};

const CourseLessonItem: React.FC<Props> = ({
  courseId,
  mapId,
  lesson,
  lessonProgress,
  duration,
  getLessonUserType,
}) => {
  const { goToLesson } = useNavigation(courseId, mapId);

  const handlePlayLesson = () => goToLesson(lesson.id, getLessonUserType);
  const tooltips = useMemo(
    () => ({
      title: "Open lesson",
      detail: `Open lesson ${lesson !== undefined ? lesson.name : ""}`,
    }),
    [lesson]
  );
  return (
    <ExtendedTooltip data={tooltips}>
      <CourseItemListItem button divider onClick={handlePlayLesson} data-testid="lesson-item">
        <LessonItemSummary lesson={lesson} lessonProgress={lessonProgress} duration={duration} />
      </CourseItemListItem>
    </ExtendedTooltip>
  );
};

export default memo(CourseLessonItem);
