import React, { ReactNode } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faBookmark } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import { IconCog } from "../../icons/IconCog";
import { tooltips } from "./tooltips";
import { Course } from "../../../../generated/graphql";
import Button from "../../Button";
import { FlexGrowContainer } from "../../Layout";
import { ExtendedTooltip } from "../../ExtendedTooltip/ExtendedTooltip";

type Props = {
  course: Course;
  isInMyCourses?: boolean | null;
  onAddToMyCoursesClick?: () => void;
  handleShareClick: () => void;
  handleChangeSettings?: () => void;
  children?: ReactNode;
};

const CoursePageTitle: React.FC<Props> = ({
  course,
  onAddToMyCoursesClick,
  isInMyCourses,
  handleShareClick,
  handleChangeSettings,
  children,
}) => {
  return (
    <FlexGrowContainer style={{ flexWrap: "wrap" }}>
      <div style={{ flex: "1 1 auto" }}>
        <FlexGrowContainer>
          <Typography variant="h4" data-testid="course-name">
            {course.name}
          </Typography>
          {handleChangeSettings && (
            <IconButton onClick={handleChangeSettings}>
              <IconCog />
            </IconButton>
          )}
        </FlexGrowContainer>
        {course.author && (
          <Typography variant="subtitle1" color="textSecondary">
            {course.author}
          </Typography>
        )}
      </div>
      <TitleButtons>
        {children}
        {onAddToMyCoursesClick && (
          <ExtendedTooltip data={isInMyCourses ? tooltips.removeFavourite : tooltips.addFavourite}>
            <ButtonBox>
              <Button color="primary" onClick={onAddToMyCoursesClick} fill={isInMyCourses ? "blue" : undefined}>
                <Typography variant="h6" style={{ padding: "0 8px" }}>
                  <FontAwesomeIcon icon={faBookmark} style={{ marginRight: 16 }} />
                  {isInMyCourses ? "Remove From My Courses" : "Add To My Courses"}
                </Typography>
              </Button>
            </ButtonBox>
          </ExtendedTooltip>
        )}
        <ExtendedTooltip data={tooltips.share}>
          <ButtonBox>
            <Button color="primary" onClick={handleShareClick}>
              <Typography variant="h6" style={{ padding: "0 8px" }}>
                <FontAwesomeIcon icon={faShare} style={{ marginRight: 16 }} />
                Share
              </Typography>
            </Button>
          </ButtonBox>
        </ExtendedTooltip>
      </TitleButtons>
    </FlexGrowContainer>
  );
};

const ButtonBox = styled.div({ marginLeft: 8 });
const TitleButtons = styled.div({ display: "flex", flexWrap: "nowrap" });

export default CoursePageTitle;
