import { isEmpty } from "lodash";
import { MapLabel } from "../generated/graphql";
import { ClientRoutes } from "../constant/clientRoutes";

export function isMapOrCourse(mapId?: string, courseId?: string): boolean {
  return !isEmpty(mapId) || !isEmpty(courseId);
}

export function getMapUrl(mapId?: string): string {
  return isEmpty(mapId) ? "/" : `/${mapId}`;
}

export function getCourseUrl(courseId?: string): string {
  return isEmpty(courseId) ? "/" : `/course/${courseId}`;
}

export function getWatchCourseUrl(courseId: string): string {
  return `${getCourseUrl(courseId)}/watch`;
}

export function getMapOrCourseUrl(mapId?: string, courseId?: string): string {
  if (!isMapOrCourse(mapId, courseId)) {
    return "/";
  }
  let url: string | undefined;
  if (courseId != null) {
    url = getCourseUrl(courseId);
  } else {
    url = getMapUrl(mapId);
  }
  return url;
}

export function getSummaryUrl(mapId?: string, courseId?: string): string {
  if (!isMapOrCourse(mapId, courseId)) {
    return "/";
  }
  return `${getMapOrCourseUrl(mapId, courseId)}/summary`;
}

const appendCloseParam = (url: string, closeTo: string): string => {
  return `${url}?close=${closeTo}`;
};

export function getLessonUrl(mapId?: string, courseId?: string, lessonId?: string): string {
  if (!isMapOrCourse(mapId, courseId)) {
    return "/";
  }
  let url = getMapOrCourseUrl(mapId, courseId);
  if (!isEmpty(lessonId)) {
    url = `${url}/lesson/${lessonId}`;
  }
  return url;
}

export function getPresentationUrl(
  mapId?: string,
  courseId?: string,
  presentationId?: string,
  closeTo?: "map" | "presentation" | undefined
): string {
  if (!isMapOrCourse(mapId, courseId)) {
    return "/";
  }
  let url = getMapOrCourseUrl(mapId, courseId);
  if (!isEmpty(presentationId)) {
    url = `${url}/presentation/${presentationId}`;
  }

  if (closeTo !== undefined) {
    url = appendCloseParam(url, closeTo);
  }

  return url;
}
export function getUserTermsUrl(showTrialVersion: boolean, showNewsletterConsent: boolean): string {
  return `${showTrialVersion ? ClientRoutes.UserTrialTerms : ClientRoutes.UserTerms}?showNewsletterConsent=${
    showNewsletterConsent ? 1 : 0
  }`;
}

export function getLabelUrl(label: MapLabel): string {
  return `/home/maps/label/${label.id}`;
}
