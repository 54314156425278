import { useCallback } from "react";
import { isLogonRequiredToViewLesson } from "../../utils/courseItemsUtils";
import { UserType } from "../../constant/userType";
import { Course, CourseItem } from "../../generated/graphql";
import { RequiredLessonUserType } from "../../src/@types/courseItem";

export function useLessonAccess(
  course: Course | undefined,
  courseItems: CourseItem[] | undefined
): { getLessonUserType: RequiredLessonUserType } {
  const getLessonUserType = useCallback(
    (lessonId: string): UserType => {
      if (isLogonRequiredToViewLesson(course, courseItems, lessonId)) return UserType.LoggedOnOnly;
      return UserType.AllUsers;
    },
    [course, courseItems]
  );
  return { getLessonUserType };
}
