import React from "react";
import { RequiredLessonUserType } from "../../../../../src/@types/courseItem";
import { PresentationUtils } from "../../../../../utils/presentationUtils";
import { AnswerAttempt, CourseItem, Map, MapProgress } from "../../../../../generated/graphql";
import LessonItem from "./CourseLessonItem";
import QuizItem from "./CourseQuizItem";
import FlashcardItem from "./CourseFlashcardItem";
import RecallItem from "./CourseRecallItem";

type Props = {
  courseId?: string;
  courseItem: CourseItem;
  map: Map;
  mapProgress?: MapProgress | null;
  mapAnswers?: AnswerAttempt[];
  getLessonUserType: RequiredLessonUserType;
  flashcardProgress?: Record<string, { correct: number; attempted: number }> | undefined;
};

const ModuleItem: React.FC<Props> = ({
  courseId,
  courseItem,
  map,
  mapAnswers = [],
  mapProgress,
  getLessonUserType,
  flashcardProgress,
}) => {
  const { presentations } = map;
  if (courseItem.__typename === "LessonItem") {
    const presentation = presentations.find(p => p.id === courseItem.presentationId);
    if (!presentation) return null;
    const duration = PresentationUtils.getDuration(presentation);
    const progress = mapProgress && mapProgress.lessons.find(p => p.lessonId === courseItem.id);
    const progressPercentage =
      progress && duration && progress.longestWatchedTime ? (progress.longestWatchedTime / duration) * 100 : 0;

    return (
      <LessonItem
        key={courseItem.id}
        duration={duration}
        courseId={courseId}
        mapId={map.id!}
        lesson={courseItem}
        lessonProgress={progressPercentage}
        getLessonUserType={getLessonUserType}
      />
    );
  }

  if (courseItem.__typename === "Quiz") {
    const answerAttempt = mapAnswers.find(attempt => attempt.quizId === courseItem.id);
    const answeredQuestions = answerAttempt ? answerAttempt.answers.length : 0;
    const totalQuestions = courseItem.questions ? courseItem.questions.length : 0;
    return (
      <QuizItem
        key={courseItem.id}
        courseId={courseId}
        mapId={map.id!}
        quiz={courseItem}
        answeredQuestions={answeredQuestions}
        totalQuestions={totalQuestions}
      />
    );
  }
  if (courseItem.__typename === "FlashcardItem") {
    return (
      <FlashcardItem
        key={courseItem.id}
        courseId={courseId}
        mapId={map.id!}
        flashcardItem={courseItem}
        progress={flashcardProgress}
      />
    );
  }
  if (courseItem.__typename === "RecallItem") {
    return <RecallItem key={courseItem.id} courseId={courseId} mapId={map.id!} recallItem={courseItem} />;
  }

  return null;
};

export default ModuleItem;
