import React from "react";
import { Course } from "../../../../generated/graphql";

type Props = {
  course: Course;
};

const CourseThumbnail: React.FC<Props> = ({ course }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${course.thumbnailURL})`,
        height: 0,
        width: "100%",
        paddingBottom: "52.5%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    />
  );
};
export default CourseThumbnail;
